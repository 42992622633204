// change path (go to url). Example (go to contact page => changePath('poll/contact'))
const changePath = function (path = "", event = false) {
  if (event) {
    event.preventDefault();
  }

  window.location.href = window.location.origin + "/" + path;
};

const daysText = function (val, arrDays) {
  val = Number(val);
  let dec;

  return ((dec = val % 100) >= 11 && dec <= 19) ||
		(dec = val % 10) >= 5 ||
		dec === 0
    ? arrDays[2]
    : dec === 1
      ? arrDays[0]
      : arrDays[1];
};

const getmeDefault = {
  acceptance: false,
  acceptanceAutoPayment: false,
  loanMinSum: 1000,
  loanMaxSum: 100000,
  loanCalcStep: 1000,
  loanSum: 10000,
  loanSumStart: 35000,
  loanTerm: 30,
  loanMinTerm: 10,
  loanMaxTerm: 90,
  loanTermCalcStep: 1,
  questionnaireId: null,
  isSubscribed: false,
  contactData: {
    lastname: "",
    firstname: "",
    patronymic: "",
    phone: "",
    email: "",
    birthday: "",
    addrcity: "",
  },
  passportData: {
    passportissuecode: "",
    passportnumber: "",
    passportseries: "",
  },
  csrf: "",
  publicApiKey: "",
  dadataApiKey: "",
  code_hash: "",
  code: "",
  userIP: "",
  accessToPage: true,
  wrongCode: false,
};

const configDefault = {
  frontConfig: {},
  languages: {},
  selectLanguage: {},
  siteConfig: {},
  dictionary: {
    common: {
      header: {},
      laonInfo: {},
      footer: {},
      popupLogin: {},
      popupSkip: {},
      popupCongratulation: {},
      popupHeader: {},
      popupAcceptance: {},
      popupSMS: {},
      popupPaymentSuccess: {},
      rangeSlider: {
        daysText: [],
      },
      fields: {},
      fieldsError: {},
      btnsText: {},
      messages: {},
    },
    index: {
      firstScreen: {},
      secendScreen: {},
      thirdScreen: {},
      fourthScreen: {},
      fifthScreen: {},
      sixthScreen: {
        questions: [],
      },
    },
    primary: {},
    contact: {},
    passport: {},
    before: {},
    card: {},
    lite: {},
    lk: {
      lkLinks: {},
    },
    login: {},
    final: {},
    unsubscribe: {
      noPhone: {},
    },
    ticket: {},
  },
};

const pagesDefault = {
  index: {
    firstScreen: {},
    secendScreen: {},
    thirdScreen: {},
    fourthScreen: {},
    fifthScreen: {},
    sixthScreen: {
      questions: [],
    },
  },
  primary: {},
  contact: {},
  passport: {},
  before: {},
  card: {},
  lk: {
    lkLinks: {},
  },
  final: {},
  unsubscribe: {
    noPhone: {},
  },
  ticket: {},
};

function getDefualtConfig(page) {
  configDefault.dictionary[page] = pagesDefault[page];
  return configDefault;
}

export { changePath, daysText, getmeDefault, configDefault, getDefualtConfig };
