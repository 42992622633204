export default function (selector) {
  const scrollTarget = document.querySelector(selector);
  if (!scrollTarget) {
    return;
  }

  const elementPosition = scrollTarget.getBoundingClientRect().top;

  window.scrollBy({
    top: elementPosition,
    behavior: "smooth",
  });
}
